import axios from 'axios';

export default class PaymentsApi {
  async getTransactions(filters) {
    let queryString = '';

    filters.methods.forEach((data, index) => {
      if (index === 0) {
        queryString = `?types=${data}`;
      } else {
        queryString += `&types=${data}`;
      }
    });

    if (filters.searchType === 'customerTaxDocument') {
      queryString += `&customerTaxDocument=${filters.customerTaxDocument}`;
    } else if (filters.searchType === 'nsu') {
      queryString += `&nsu=${filters.nsu}`;
    } else if (filters.searchType === 'bankslipId') {
      queryString += `&bankSlipId=${filters.bankSlipId}`;
    } else if (filters.searchType === 'paymentId') {
      queryString += `&paymentId=${filters.paymentId}`;
    } else if (filters.searchType === 'note') {
      queryString += `&description=${filters.description}`;
    } else if (filters.searchType === 'payerName') {
      queryString += `&customerName=${filters.customerName}`;
    }

    if (filters.cardBrands !== null && filters.cardBrands !== 'null') {
      queryString += `&cardBrand=${filters.cardBrands}`;
    }

    if (filters.bracket !== null && filters.bracket !== 'null') {
      queryString += `&bracket=${filters.bracket}`;
    }

    if (filters.sellingChannels !== null) {
      queryString += `&sellingChannels=${filters.sellingChannels}`;
    }

    if (filters.sellingKey !== null) {
      queryString += `&sellingKey=${filters.sellingKey}`;
    }

    var statusArray =
      filters.status.paid &&
      filters.status.pending &&
      filters.status.overdue &&
      filters.status.canceled &&
      filters.status.unauthorized
        ? ['created']
        : [
            ...(filters.status.paid ? ['paid'] : []),
            ...(filters.status.pending ? ['pending'] : []),
            ...(filters.status.overdue ? ['overdue'] : []),
            ...(filters.status.canceled
              ? ['contested', 'canceled', 'expired']
              : []),
            ...(filters.status.unauthorized ? ['reproved'] : []),
          ];

    for (var i = 0; i < statusArray.length; i++) {
      queryString += `&status[${i}]=${statusArray[i]}`;
    }

    const result = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_SHORT_API_URL
      }/v3/transactions${queryString}&startDate=${
        filters.interval.startDate
      }&endDate=${filters.interval.endDate}&index=${
        filters.offset.index
      }&length=${filters.offset.limit}`
    );

    return result.data;
  }

  async getSellersTransactions(filters) {
    let queryString = '';

    if (filters) {
      filters.methods.forEach((data, index) => {
        if (index === 0) {
          queryString = `?types=${data}`;
        } else {
          queryString += `&types=${data}`;
        }
      });

      if (filters.searchType === 'holderTaxDocument') {
        queryString += `&holderTaxDocuments=${filters.holderTaxDocument}`;
      } else if (filters.searchType === 'nsu') {
        queryString += `&nsu=${filters.nsu}`;
      } else if (filters.searchType === 'bankSlipNumber') {
        queryString += `&bankSlipNumber=${filters.bankSlipNumber}`;
      } else if (filters.searchType === 'paymentId') {
        queryString += `&paymentId=${filters.paymentId}`;
      } else if (filters.searchType === 'note') {
        queryString += `&description=${filters.description}`;
      } else if (filters.searchType === 'payerName') {
        queryString += `&holderNames=${filters.holderNames}`;
      }

      if (filters.cardBrands !== null && filters.cardBrands !== 'null') {
        queryString += `&cardBrand=${filters.cardBrands}`;
      }

      if (filters.bracket !== null && filters.bracket !== 'null') {
        queryString += `&bracket=${filters.bracket}`;
      }

      if (filters.sellingChannels !== null) {
        queryString += `&sellingChannels=${filters.sellingChannels}`;
      }

      if (filters.sellingKey !== null) {
        queryString += `&sellingKey=${filters.sellingKey}`;
      }

      var statusArray =
        filters.status.paid &&
        filters.status.pending &&
        filters.status.overdue &&
        filters.status.canceled &&
        filters.status.unauthorized
          ? ['created']
          : [
              ...(filters.status.paid ? ['paid'] : []),
              ...(filters.status.pending ? ['pending'] : []),
              ...(filters.status.overdue ? ['overdue'] : []),
              ...(filters.status.canceled
                ? ['contested', 'canceled', 'expired']
                : []),
              ...(filters.status.unauthorized ? ['reproved'] : []),
            ];

      for (var i = 0; i < statusArray.length; i++) {
        queryString += `&status[${i}]=${statusArray[i]}`;
      }
    }

    const params = {
      startDate: filters.interval.startDate,
      endDate: filters.interval.endDate,
    };

    const result = await axios.get(
      `${
        process.env.VUE_APP_PAYMENTS_SHORT_API_URL
      }/v3/partner/transactions/${queryString}`,
      { params }
    );

    return result.data;
  }

  async findSellerPayment(paymentId) {
    const url = `${
      process.env.VUE_APP_PAYMENTS_SHORT_API_URL
    }/v3/partner/transactions/find/${paymentId}`;
    const result = await axios.get(url);

    return result.data;
  }

  async categoriesProduct() {
    const url = `${
      process.env.VUE_APP_PAYMENTS_SHORT_API_URL
    }/v3/categories-product`;
    const result = await axios.get(url);

    return result.data;
  }
}
