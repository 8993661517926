<template>
  <div class="mt-5">
    <div class="container">
      <div
        v-if="payment.bankSlips.length > 0"
        class="row"
        :class="payment.bankSlips.length === 1 ? 'justify-content-center' : ''"
      >
        <div
          v-if="payment.bankSlips.length > 1"
          class="col-12 col-w-full col-lg-6"
        >
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                {{
                  isBankSlip ? 'Substituição de Boleto' : 'Parcelas do Carnê'
                }}
              </h3>
              <div class="mr-3 mt-2 ml-auto text-right">
                <h6 class="font-weight-light m-0">
                  {{
                    payment.amount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </h6>
              </div>
            </div>
            <hr />
            <div>
              <table class="table">
                <tbody>
                  <tr
                    class="clickable-row"
                    v-for="bankSlip of payment.bankSlips"
                    :key="bankSlip.number"
                    @click="selectBankSlip(bankSlip)"
                  >
                    <label>
                      <input
                        type="radio"
                        name="slipbank"
                        :checked="
                          bankSlip.installmentNumber ===
                          displayedBankSlip.installmentNumber
                        "
                      />
                      <div class="d-table clickable-row w-100">
                        <td v-if="!isBankSlip" scope="row">
                          {{ bankSlip.installmentNumber }}ª parcela
                        </td>
                        <td v-if="isBankSlip" scope="row">
                          boleto
                          {{
                            bankSlip.status === 'canceled' ||
                            bankSlip.status === 'autoCanceled'
                              ? 'substituído'
                              : 'atual'
                          }}
                        </td>
                        <td>
                          <small class="text-muted"> data de vencimento </small>
                          <br />{{ bankSlip.dueDate | moment('DD/MM/YYYY') }}
                        </td>
                        <td>
                          <small class="text-muted">valor</small>
                          <br />
                          {{
                            bankSlip.amount
                              | currency('R$ ', 2, {
                                decimalSeparator: ',',
                                thousandsSeparator: '.',
                              })
                          }}
                        </td>
                        <td class="w-25">
                          <small class="text-muted">status</small>
                          <br />
                          <span
                            class="badge badge-pill"
                            :class="statusColor(bankSlip.status, 'bankSlip')"
                          >
                            <p class="text-uppercase m-0">
                              {{
                                getStatusPayment(bankSlip.status, 'bankSlip')
                              }}
                            </p>
                          </span>
                        </td>
                      </div>
                    </label>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-w-full col-lg-6">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                Pagamento no Boleto
              </h3>
              <div class="ml-auto mr-3 text-right">
                <h6 class="font-weight-light m-0">
                  {{
                    displayedBankSlip.amount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </h6>
                <small class="text-muted m-0 text-nowrap">
                  Valor do boleto
                </small>
              </div>
            </div>
            <hr />
            <div class="card-content">
              <div class="d-flex mb-4 mx-1">
                <div class="pull-left mr-auto">
                  <span class="d-flex payment-method boleto mr-auto">
                    <span class="d-none">boletos</span>
                  </span>
                </div>
                <div
                  v-if="
                    !(
                      displayedBankSlip.status === 'canceled' ||
                      displayedBankSlip.status === 'autoCanceled'
                    ) && paymentsPermission
                  "
                  class="dropdown pull-right"
                >
                  <button
                    type="button"
                    class="btn btn-outline-orange btn-pill dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Ações
                    <span class="caret" />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        v-if="canBankSlipBeCanceled"
                        href="javascript:;"
                        class="dropdown-item"
                        @click.prevent="
                          cancelBankSlip(
                            displayedBankSlip.number,
                            displayedBankSlip.installmentNumber
                          )
                        "
                      >
                        Cancelar este boleto
                      </a>
                    </li>
                    <li
                      v-if="
                        payment.bankSlips.length > 1 &&
                        displayedBankSlip.status !== 'paid'
                      "
                    >
                      <a
                        href="javascript:;"
                        class="dropdown-item"
                        @click.prevent="
                          printSingleBankSlip(displayedBankSlip.number)
                        "
                      >
                        Imprimir este boleto
                      </a>
                    </li>
                    <li>
                      <a
                        v-if="displayedBankSlip.status !== 'paid'"
                        href="javascript:;"
                        class="dropdown-item"
                        @click.prevent="printBankSlip()"
                      >
                        Imprimir
                        {{
                          payment.bankSlips.length === 1
                            ? 'boleto'
                            : 'carnê completo'
                        }}
                      </a>
                    </li>
                    <li>
                      <router-link
                        v-if="displayedBankSlip.status !== 'paid'"
                        class="dropdown-item"
                        :to="{
                          name: 'change-bankslip',
                          params: {
                            bankSlipNumber: displayedBankSlip.number,
                            maxDiscount: maxDiscount,
                          },
                        }"
                      >
                        Substituir este boleto
                      </router-link>
                    </li>
                    <li>
                      <span
                        v-if="displayedBankSlip.status !== 'paid'"
                        class="dropdown-item cursor-pointer"
                        @click="
                          sendSingleToEmail(
                            displayedBankSlip.payerEmail !== null
                              ? displayedBankSlip.payerEmail
                              : ''
                          )
                        "
                      >
                        <span v-if="payment.bankSlips.length > 1">
                          Enviar este boleto por e-mail
                        </span>
                        <span v-if="payment.bankSlips.length < 2">
                          Enviar por e-mail
                        </span>
                      </span>
                    </li>
                    <li
                      v-if="
                        payment.bankSlips.length > 1 &&
                        displayedBankSlip.status !== 'paid'
                      "
                    >
                      <span
                        class="dropdown-item cursor-pointer"
                        @click="
                          sendToEmail(
                            displayedBankSlip.payerEmail !== null
                              ? displayedBankSlip.payerEmail
                              : ''
                          )
                        "
                      >
                        <span>Enviar carnê completo por e-mail</span>
                      </span>
                    </li>
                    <li
                      v-if="
                        !payment.cancelable &&
                        displayedBankSlip.status === 'paid'
                      "
                      disabled
                    >
                      <span class="dropdown-item cursor-pointer">
                        <span>Sem ações</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">Cliente:</h6>
                  <p class="m-0">{{ displayedBankSlip.payerName }}</p>
                  <small class="m-0">
                    {{
                      displayedBankSlip.payerTaxDocument.length > 14
                        ? 'CNPJ'
                        : 'CPF'
                    }}: {{ displayedBankSlip.payerTaxDocument }}
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Data e hora da emissão:
                  </h6>
                  <p class="m-0">
                    {{
                      displayedBankSlip.createdAt
                        | moment('DD/MM/YYYY HH:mm:ss')
                    }}
                  </p>
                </div>
                <div class="col-12 col-md-5 col-lg-12 col-xl-5 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Nosso Número:
                  </h6>
                  <p class="m-0">{{ displayedBankSlip.number }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">Status:</h6>
                  <div class="d-flex">
                    <div class="mr-2">
                      <span
                        class="badge badge-pill"
                        :class="
                          statusColor(displayedBankSlip.status, 'bankSlip')
                        "
                      >
                        <p class="text-uppercase m-0">
                          {{
                            getStatusPayment(
                              displayedBankSlip.status,
                              'bankSlip'
                            )
                          }}
                        </p>
                      </span>
                    </div>
                    <p v-if="displayedBankSlip.status === 'paid'" class="m-0">
                      {{
                        displayedBankSlip.paidAt | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p
                      v-if="displayedBankSlip.status === 'canceled'"
                      class="m-0"
                    >
                      {{
                        displayedBankSlip.canceledAt
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p
                      v-if="displayedBankSlip.status === 'autoCanceled'"
                      class="m-0"
                    >
                      {{
                        displayedBankSlip.autoCanceledAt
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="displayedBankSlip.sellingKey != null" class="row">
                <div class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Chave de transação:
                  </h6>
                  <p class="m-0">{{ displayedBankSlip.sellingKey }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">Vencimento:</h6>
                  <p class="m-0">
                    {{ displayedBankSlip.dueDate | moment('DD/MM/YYYY') }}
                  </p>
                </div>
                <div class="col-12 col-md-5 col-lg-12 col-xl-5 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Limite para pagamento:
                  </h6>
                  <p class="m-0">
                    {{ displayedBankSlip.payableUntil | moment('DD/MM/YYYY') }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div
                  v-if="displayedBankSlip.interest !== null"
                  class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4"
                >
                  <h6 class="font-weight-light text-muted mb-1">
                    Juros por atraso (ao mês):
                  </h6>
                  <p class="m-0">{{ `${displayedBankSlip.interest}%` }}</p>
                </div>
                <div
                  v-if="displayedBankSlip.fines !== null"
                  class="col-12 col-md-5 col-lg-12 col-xl-5 mb-4"
                >
                  <h6 class="font-weight-light text-muted mb-1">
                    Multa por atraso:
                  </h6>
                  <p class="m-0">{{ `${displayedBankSlip.fines}%` }}</p>
                </div>
                <div
                  v-if="displayedBankSlip.discount !== null"
                  class="col-12 col-md-7 col-lg-12 col-xl-7 mb-4"
                >
                  <h6 class="font-weight-light text-muted mb-1">Desconto:</h6>
                  <p class="m-0">
                    {{
                      displayedBankSlip.discount !== null
                        ? `${displayedBankSlip.discount}% de desconto até `
                        : ''
                    }}{{
                      displayedBankSlip.discountLimitDate | moment('DD/MM/YYYY')
                    }}
                  </p>
                </div>
              </div>
              <div
                v-if="payment.note !== '' || payment.note !== null"
                class="row"
              >
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Descrição do pagamento:
                  </h6>
                  <p class="m-0">{{ payment.note }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Código de barras:
                  </h6>
                  <p class="m-0">{{ displayedBankSlip.barcode }}</p>
                </div>
              </div>
              <div v-if="payment.id != null" class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Id do Pagamento:
                  </h6>
                  <p class="m-0">{{ payment.id }}</p>
                </div>
              </div>
              <div v-if="payment.sellingKey != null" class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Chave do Pagamento:
                  </h6>
                  <p class="m-0">{{ payment.sellingKey }}</p>
                </div>
              </div>
              <div v-if="displayedBankSlip.instructions !== null" class="row">
                <div class="col-12 mb-4 small-paragraph">
                  <h6 class="font-weight-light text-muted mb-1">Instruções:</h6>
                  <small class="m-0">
                    {{ displayedBankSlip.instructions }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="payment.cardTransactions.length > 0"
        class="row"
        :class="
          payment.bankSlips.length === 0
            ? 'justify-content-center'
            : 'justify-content-end'
        "
      >
        <div class="col-12 col-lg-10">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                Pagamento no Cartão
              </h3>
              <div class="ml-auto mr-3 text-right">
                <h3 class="font-weight-light m-0">
                  {{
                    payment.amount
                      | currency('R$ ', 2, {
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </h3>
              </div>
            </div>
            <hr />
            <div
              class="card-content"
              v-for="(cardTransaction, index) of payment.cardTransactions"
              :key="index"
            >
              <div class="d-flex mb-4 mx-1">
                <div class="pull-left mr-auto">
                  <span
                    class="d-flex payment-method"
                    :class="cardTransaction.cardBrand"
                  >
                    <span class="d-none">Cartão</span>
                    <div
                      class="align-self-center ml-2 small-paragraph text-left"
                    >
                      <small>
                        {{ cardTransaction.cardFinal }}
                        {{ cardTransaction.credit ? 'crédito' : 'débito' }} -
                        {{ cardTransaction.online ? 'digitada' : 'pinpad' }}
                      </small>
                      <span
                        v-if="cardTransaction.isAuthorization"
                        class="badge badge-light-gray text-secondary badge-pill ml-2"
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="
                          cardTransaction.status === 'pending'
                            ? 'Essa transação foi pré autorizada e pode ter o valor alterado em sua finalização.'
                            : 'Essa transação foi pré autorizada'
                        "
                      >
                        PRÉ
                      </span>
                    </div>
                  </span>
                </div>
                <div
                  v-if="
                    cardTransaction.amountAuthorized !== null &&
                    cardTransaction.amountAuthorized !==
                      cardTransaction.amountPaid
                  "
                  class="ml-5 text-right pull-right"
                >
                  <h4 class="font-weight-light m-0">
                    <del>
                      {{
                        cardTransaction.amountAuthorized
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </del>
                  </h4>
                  <h6 class="font-weight-light m-0">
                    <del>
                      {{ cardTransaction.installments }} X
                      {{
                        cardTransaction.installmentValue
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </del>
                  </h6>
                </div>
                <div
                  v-if="
                    !cardTransaction.isAuthorized ||
                    cardTransaction.capturedAt !== null
                  "
                  class="ml-2 text-right pull-right"
                >
                  <h4 class="font-weight-light m-0">
                    {{
                      cardTransaction.amountPaid
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                  <h6 class="font-weight-light m-0">
                    {{ cardTransaction.installments }} X
                    {{
                      cardTransaction.installmentValue
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Data e hora da transação:
                  </h6>
                  <p class="m-0">
                    {{ payment.createdAt | moment('DD/MM/YYYY HH:mm:ss') }}
                  </p>
                </div>
                <div class="col-2 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">NSU:</h6>
                  <p class="m-0">{{ cardTransaction.nsu }}</p>
                </div>
                <div class="col-3">
                  <div
                    v-if="
                      paymentsPermission &&
                      (cardTransaction.status === 'approved' ||
                        cardTransaction.status === 'canceled' ||
                        cardTransaction.status === 'pending')
                    "
                    class="dropdown pull-right"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-orange btn-pill dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      Ações
                      <span class="caret" />
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          v-if="
                            payment.cancelable &&
                            cardTransaction.status !== 'canceled'
                          "
                          href="javascript:;"
                          class="dropdown-item"
                          @click.prevent="
                            cancelCardTransaction(cardTransaction.nsu)
                          "
                        >
                          Cancelar este pagamento no cartão
                        </a>
                        <router-link
                          v-if="
                            cardTransaction.status === 'approved' ||
                            cardTransaction.status === 'canceled'
                          "
                          :to="{
                            name: 'payment-invoice',
                            params: { paymentId: payment.id },
                          }"
                          class="dropdown-item"
                        >
                          Visualizar comprovante
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-w-full col-md-7 col-lg-12 col-xl-7 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">Status:</h6>
                  <div class="d-flex">
                    <div class="mr-2">
                      <span
                        class="badge badge-pill"
                        :class="statusColor(cardTransaction.status, 'card')"
                      >
                        <p class="text-uppercase m-0">
                          {{ getStatusPayment(cardTransaction.status, 'card') }}
                        </p>
                      </span>
                      <span
                        v-if="cardTransaction.inDispute"
                        class="badge badge-pill"
                        :class="statusColor('reproved', 'card')"
                        :style="'margin-left:10px'"
                      >
                        <p class="text-uppercase m-0">Em disputa</p>
                      </span>
                    </div>
                    <p
                      v-if="cardTransaction.status === 'pending'"
                      class="text-warning m-0"
                    />
                    <p
                      v-if="
                        cardTransaction.status === 'approved' &&
                        cardTransaction.capturedAt !== null
                      "
                      class="m-0"
                    >
                      {{
                        cardTransaction.capturedAt
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p
                      v-if="
                        cardTransaction.status === 'approved' &&
                        !cardTransaction.isAuthorized &&
                        cardTransaction.capturedAt === null
                      "
                      class="m-0"
                    >
                      {{
                        cardTransaction.paidAt | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p v-if="cardTransaction.status === 'canceled'" class="m-0">
                      {{
                        cardTransaction.canceledAt
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p
                      v-if="cardTransaction.status === 'chargeback'"
                      class="m-0"
                    >
                      {{
                        cardTransaction.contestedOn
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p v-if="cardTransaction.status === 'reproved'" class="m-0">
                      {{
                        cardTransaction.reprovedAt
                          | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                    <p
                      v-if="cardTransaction.status === 'registrationError'"
                      class="m-0"
                    >
                      {{
                        cardTransaction.error | moment('DD/MM/YYYY HH:mm:ss')
                      }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="payment.cardTransactions[0].installments > 1"
                  class="col-12 col-w-full col-md-5 col-lg-12 col-xl-5 mb-4"
                >
                  <h6 class="font-weight-light text-muted mb-1">
                    Valor das parcelas:
                  </h6>
                  <p class="m-0">
                    {{ payment.cardTransactions[0].installments }} x
                    {{
                      payment.cardTransactions[0].installmentValue
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </p>
                </div>
              </div>
              <div v-if="cardTransaction.isAuthorization" class="row">
                <div
                  v-if="cardTransaction.capturedAt !== null"
                  class="col-7 mb-4"
                >
                  <h6 class="font-weight-light text-muted mb-1">
                    Data e hora da finalização:
                  </h6>
                  <p class="m-0">
                    {{
                      cardTransaction.capturedAt | moment('DD/MM/YYYY HH:mm:ss')
                    }}
                  </p>
                </div>
                <div class="col-2 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Limite para pagamento:
                  </h6>
                  <p class="m-0">
                    {{ cardTransaction.dueCaptureDate | moment('DD/MM/YYYY') }}
                  </p>
                </div>
              </div>
              <div v-if="payment.note !== null" class="row">
                <div class="col-12 col-w-full mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Descrição do pagamento:
                  </h6>
                  <p class="m-0">{{ payment.note }}</p>
                </div>
              </div>
              <div v-if="payment.id != null" class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Id do Pagamento:
                  </h6>
                  <p class="m-0">{{ payment.id }}</p>
                </div>
              </div>
              <div v-if="cardTransaction.errorMessage != null" class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Motivo da Reprovação:
                  </h6>
                  <p class="m-0">{{ cardTransaction.errorMessage }}</p>
                </div>
              </div>
              <div v-if="payment.sellingKey != null" class="row">
                <div class="col-12 mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Chave do Pagamento:
                  </h6>
                  <p class="m-0">{{ payment.sellingKey }}</p>
                </div>
              </div>
              <div v-if="cardTransaction.sellingKey != null" class="row">
                <div class="col-12 col-w-full mb-4">
                  <h6 class="font-weight-light text-muted mb-1">
                    Chave de Transação:
                  </h6>
                  <p class="m-0">{{ cardTransaction.sellingKey }}</p>
                </div>
              </div>
              <hr v-if="payment.cardTransactions.length > index + 1" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="payment.splitters.length > 0" class="container">
      <div
        v-if="payment.cardTransactions.length > 0"
        class="row"
        :class="
          payment.bankSlips.length === 0
            ? 'justify-content-center'
            : 'justify-content-end'
        "
      >
        <div class="col-12 col-lg-10">
          <div class="card">
            <div class="d-flex">
              <h3 class="font-weight-light ml-3 mr-auto">
                Divisão do Pagamento
              </h3>
              <a
                data-toggle="collapse"
                data-parent="#accordion"
                href="#splitPayment"
                aria-expanded="true"
                aria-controls="splitPayment"
                @click.prevent="openSplit()"
              >
                <h2 class="pr-2">
                  <i
                    class="fa"
                    :class="splitOpen ? 'fa-angle-up' : 'fa-angle-down'"
                  />
                </h2>
              </a>
            </div>
            <hr />
            <div
              id="splitPayment"
              class="collapse hide"
              role="tabpanel"
              aria-labelledby="headingOne1"
              data-parent="#accordion"
            >
              <div
                class="card-content mb-2"
                v-for="(split, index) of payment.splitters"
                :key="index"
              >
                <div class="row mb-2">
                  <div class="col-4">
                    <p class="m-0">
                      {{
                        split.isCommissionedHolder
                          ? 'Recebedor Comissionado'
                          : 'Splitter'
                      }}
                    </p>
                  </div>
                  <div class="col-2 text-center">
                    <p class="m-0">Comissão</p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">Vl. bruto Splitter</p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">Vl. bruto Estabelecimento</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <p class="m-0">{{ split.name }}</p>
                  </div>
                  <div class="col-2 text-center">
                    <p class="m-0">
                      {{
                        split.salesCommission
                          | currency('', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}%
                    </p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">
                      {{
                        split.amount
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">
                      {{
                        split.amountCommission
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div class="card-content mb-2">
                <div class="row">
                  <div class="col-9">
                    <p class="m-0">
                      Valor bruto do Estabelecimento na transação
                    </p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">
                      {{
                        payment.amountSeller
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-content mb-2">
                <div class="row">
                  <div class="col-9">
                    <p class="m-0">Total bruto do estabelecimento</p>
                  </div>
                  <div class="col-3 text-center">
                    <p class="m-0">
                      {{
                        payment.sellerTotalAmount
                          | currency('R$ ', 2, {
                            decimalSeparator: ',',
                            thousandsSeparator: '.',
                          })
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import swal from 'sweetalert2';
import { mapGetters } from 'vuex';
import PaymentsApi from '@/services/PaymentsApi';
import PaymentsApiV3 from '@/services/v3/PaymentsApi';
import { PaymentFunctions } from '@/models/Payment';
import { PermissionList } from '@/models/Permission';
import Alert from '@/models/Alert';
import BankSlipPdfError from '@/errors/BankSlipPdfError';

export default {
  name: 'AppPaymentDetails',

  data: () => ({
    payment: {
      cardTransactions: [],
      bankSlips: [],
      splitters: [],
    },
    paymentFunctions: new PaymentFunctions(),
    permissionList: new PermissionList(),
    displayedBankSlip: '',
    customer: {
      email: '',
    },
    splitOpen: false,
  }),

  computed: {
    isBankSlip() {
      for (let bankSlip of this.payment.bankSlips) {
        if (bankSlip.installmentNumber !== 1) return false;
      }

      return true;
    },
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments'
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    paymentsPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    maxDiscount() {
      const discountMaximum = Number(
        (100 - (10 * 100) / this.displayedBankSlip.amount).toFixed(2)
      );
      return discountMaximum;
    },
    canBankSlipBeCanceled() {
      return (
        this.displayedBankSlip.status === 'pending' ||
        this.displayedBankSlip.status === 'delayed'
      );
    },
    amountHolder() {
      const amountHolder = this.payment.amount - this.payment.amountSplit;
      return amountHolder;
    },
    percentAmountHolder() {
      const percentAmountHolder = 100 - this.payment.percentSplit;
      return percentAmountHolder;
    },
  },

  async mounted() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.$bus.$emit('spinner-run');
    await this.find();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');
  },

  methods: {
    async find() {
      this.$bus.$emit('spinner-run');
      if (this.$route.params.isPartner && this.isOriginPartner()) {
        const api = new PaymentsApiV3();
        this.payment = await api.findSellerPayment(
          this.$route.params.paymentId
        );
      } else {
        const api = new PaymentsApi();
        this.payment = await api.findPayment(this.$route.params.paymentId);
      }
      this.$bus.$emit('spinner-stop');
      if (this.payment && this.payment.bankSlips.length > 0) {
        this.displayedBankSlip = this.payment.bankSlips[0];
      }
    },

    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByPartner;
    },

    getStatusPayment(type, transaction) {
      switch (type) {
        case 'paid':
          return 'PAGO';
        case 'approved':
          return 'APROVADO';
        case 'pending':
          return transaction === 'bankSlip' ? 'A VENCER' : 'EM ABERTO';
        case 'analyzing':
          return 'EM ANÁLISE';
        case 'delayed':
          return 'VENCIDO';
        case 'canceled':
          return 'CANCELADO';
        case 'autoCanceled':
          return 'EXPIRADO';
        case 'chargeback':
          return 'CONSTESTADO';
        case 'registrationError':
          return 'ERRO NO REGISTRO';
        case 'reproved':
          return 'REPROVADO';
        case 'unauthorized':
          return 'NÃO AUTORIZADO';
        case 'manualCanceled':
          return 'CANCELADO PELO USUARIO';
        case 'created':
          return 'EMITIDO';
        case 'disputed':
          return 'EM DISPUTA';
        case 'disputeSucceeded':
          return 'DISPUTA VENCIDA PELO ESTABELECIMENTO';
        case 'captured':
          return 'CAPTURADO';
        default:
          break;
      }
    },

    selectBankSlip(bankSlip) {
      this.displayedBankSlip = bankSlip;
    },

    statusColor(type, transaction) {
      return {
        'badge-processing': type === 'pending' && transaction === 'card',
        'badge-alert': type === 'analyzing',
        'badge-success':
          type === 'paid' || type === 'approved' || type === 'disputeSucceeded',
        'badge-delayed': type === 'delayed',
        'badge-reproved': type === 'reproved',
        'badge-error': type === 'registrationError',
        'badge-pending': type === 'pending' && transaction === 'bankSlip',
        'badge-canceled': type === 'canceled' || type === 'manualCanceled',
        'badge-contested':
          type === 'chargeback' ||
          type === 'autoCanceled' ||
          type === 'disputed',
      };
    },

    async cancelBankSlip(number, installmentNumber) {
      this.$alert.question(
        'Cancelamento de boleto',
        'Deseja realmente cancelar o boleto?'
      );
      this.$alert.onConfirm = async () => {
        this.$bus.$emit('spinner-run');
        const api = new PaymentsApi();
        await api.cancelBankslip(number);
        this.payment = await api.findPayment(this.$route.params.paymentId);
        this.displayedBankSlip = this.payment.bankSlips[installmentNumber - 1];
        this.$bus.$emit('spinner-stop');
      };
    },

    async printBankSlip() {
      const api = new PaymentsApi();
      this.$bus.$emit('spinner-run');
      // PaymentsApi returns null for success, string msg for error
      var error = await api.bankSlipPdf(this.$route.params.paymentId);
      this.$bus.$emit('spinner-stop');
      if (error) {
        const modelError = new BankSlipPdfError(error);
        var alert = new Alert();
        alert.title = 'Ops';
        alert.error = modelError.getMessage();
        alert.confirmButtonText = 'Sair';
        this.$bus.$emit('alert-error', alert);
      }
    },

    async printSingleBankSlip(bankslipId) {
      const api = new PaymentsApi();
      this.$bus.$emit('spinner-run');

      await api.singleBankSlipPdf(bankslipId);

      this.$bus.$emit('spinner-stop');
    },

    async cancelCardTransaction(nsu) {
      this.$alert.question(
        'Cancelamento de transação',
        'Deseja realmente cancelar a transação?'
      );
      this.$alert.onConfirm = async () => {
        this.$bus.$emit('spinner-run');
        const api = new PaymentsApi();
        await api.cancelCardTransaction(nsu);
        this.payment = await api.findPayment(this.$route.params.paymentId);
        this.$bus.$emit('spinner-stop');
      };
    },

    getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },

    async sendSingleToEmail(payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();
          await api
            .singleBankSlipSendToEmail(this.displayedBankSlip.id, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Boleto enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },

    async sendToEmail(payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();
          await api
            .bankSlipSendToEmail(this.$route.params.paymentId, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Carnê enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },

    openSplit() {
      this.splitOpen = !this.splitOpen;
    },
  },
};
</script>
