export class PaymentFunctions {
  getStatus(status) {
    switch (status) {
    case 'paid':
      return 'PAGO';
    case 'approved':
      return 'APROVADO';
    case 'pending':
      return 'A VENCER';
    case 'delayed':
      return 'VENCIDO';
    case 'canceled':
      return 'CANCELADO';
    case 'autoCanceled':
      return 'EXPIRADO';
    case 'contested':
      return 'CONSTESTADO';
    case 'registrationError':
      return 'ERRO NO REGISTRO';
    case 'reproved':
      return 'REPROVADO';
    case 'unauthorized':
      return 'NÃO AUTORIZADO';
    case 'disputed':
      return 'EM DISPUTA';
    case 'captured':
      return 'CAPTURADO';
    case 'disputeSucceeded':
      return 'DISPUTA VENCIDA PELO ESTABELECIMENTO';
    case 'custodied':
      return 'CUSTODIADO';
    default:
      break;
    }
  }
}
