<template>
  <div>
    <div class="header-box pt-5 mb-5">
      <div class="row justify-content-center mx-0 mb-4 payment-overview">
        <div class="col-sm-12 col-xl-10">
          <form class="form-group">
            <div class="row justify-content-center mx-0 payment-overview">
              <div class="col-lg-5 col-xl-5 align-self-end mr-4">
                <div class="row">
                  <label class="radio-selector col-sm-6 mb-3">
                    <input type="checkbox" v-model="last7Days" />
                    <span class="pill-radio"> Últimos 7 dias </span>
                  </label>
                  <label class="radio-selector col-sm-6 mb-3">
                    <input type="checkbox" v-model="last30Days" />
                    <span class="pill-radio"> Últimos 30 dias </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-5 col-xl-5 align-self-end ml-4">
                <div class="row justify-content-center">
                  <div class="col-10 text-center">
                    <h6
                      class="
                        font-weight-light
                        text-muted
                        pb-2
                        mb-3
                        border-bottom
                      "
                    >
                      Ou informar um período e cliente específico
                    </h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 mb-3">
                    <input
                      type="date"
                      class="form-control input-pill"
                      placeholder="Informar data inicial"
                      v-model="startDate"
                    />
                  </div>
                  <div class="col-sm-6 mb-3">
                    <input
                      type="date"
                      class="form-control input-pill"
                      placeholder="Informar data final"
                      v-model="endDate"
                    />
                    <small
                      class="text-danger"
                      v-if="this.startDate > this.endDate"
                    >
                      A data final necessita ser posterior a data inicial
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                row
                justify-content-center
                mx-0
                pl-4
                pr-4
                ml-4
                mr-4
                payment-overview
              "
            >
              <div class="col-sm-12 col-xl-8">
                <div class="row justify-content-center">
                  <label class="radio-selector col-sm-4 mb-3">
                    <input type="checkbox" v-model="bankSlip" />
                    <span class="pill-radio"> Boleto </span>
                  </label>
                  <label class="radio-selector col-sm-4 mb-3">
                    <input type="checkbox" v-model="credit" />
                    <span class="pill-radio"> Crédito </span>
                  </label>
                  <label class="radio-selector col-sm-4 mb-3">
                    <input type="checkbox" v-model="debit" />
                    <span class="pill-radio"> Débito </span>
                  </label>
                </div>
              </div>
            </div>
            <div
              class="
                row
                justify-content-center
                mx-0
                pb-4
                col-xl-12
                payment-overview
              "
            >
              <div class="col-lg-8 col-xl-8 align-self-end mr-4">
                <div class="row">
                  <div class="col-4 p-0">
                    <select
                      class="form-control input-pill"
                      v-model="searchType"
                    >
                      <option
                        value="payerTaxDocument"
                        v-if="bankSlip || (!credit && !debit && !bankSlip)"
                      >
                        CPF/CNPJ do estabelecimento
                      </option>
                      <option
                        value="payerName"
                        v-if="bankSlip || (!credit && !debit && !bankSlip)"
                      >
                        Nome do cliente
                      </option>
                      <option
                        value="bankslipId"
                        v-if="bankSlip || (!credit && !debit && !bankSlip)"
                      >
                        Nosso número
                      </option>
                      <option
                        value="nsu"
                        v-if="
                          credit || debit || (!credit && !debit && !bankSlip)
                        "
                      >
                        NSU
                      </option>
                      <option
                        value="cardFinal"
                        v-if="
                          credit || debit || (!credit && !debit && !bankSlip)
                        "
                      >
                        Final do cartão
                      </option>
                      <option value="note">Descrição do pagamento</option>
                    </select>
                  </div>
                  <div class="col-8" v-show="searchType === 'payerTaxDocument'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="CPF/CNPJ do estabelecimento"
                      maxlength="18"
                      v-model="payerTaxDocument"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                    />
                  </div>
                  <div class="col-8" v-show="searchType === 'bankslipId'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="Nosso número"
                      v-model="bankslipId"
                    />
                  </div>
                  <div class="col-8" v-show="searchType === 'nsu'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="NSU"
                      v-model="nsu"
                    />
                  </div>
                  <div class="col-8" v-show="searchType === 'cardFinal'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="Final do cartão"
                      maxlength="4"
                      v-model="cardFinal"
                    />
                  </div>
                  <div class="col-8" v-show="searchType === 'payerName'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="Nome"
                      v-model="payerName"
                    />
                  </div>
                  <div class="col-8" v-show="searchType === 'note'">
                    <input
                      type="text"
                      class="form-control input-pill h-100"
                      placeholder="Descrição do pagamento"
                      maxlength="120"
                      v-model="note"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-lg-2 col-xl-2 align-self-end ml-4"
                v-if="credit || debit || (!credit && !debit && !bankSlip)"
              >
                <div class="row">
                  <select class="form-control input-pill" v-model="cardBrand">
                    <option value="">Selecione sua bandeira</option>
                    <option value="amex">American Express</option>
                    <option value="banesecard">Banese Card</option>
                    <option value="dinersclub">Diners Club</option>
                    <option value="elo">Elo</option>
                    <option value="hipercard">HiperCard</option>
                    <option value="mastercard">MasterCard</option>
                    <option value="visa">Visa</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row justify-content-center mx-0 pb-4 payment-overview">
        <div class="col-12 col-w-full col-xl-11">
          <div class="w-100 d-flex mx-0 justify-content-center">
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Pago/Aprovado</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="paid" />
                  <div class="result d-flex align-itens-center border-success">
                    <h3 class="m-auto font-weight-light">
                      {{ total.paidQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.paidAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Cancelado</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="canceled" />
                  <div class="result d-flex align-itens-center border-canceled">
                    <h3 class="m-auto font-weight-light">
                      {{ total.canceledQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.canceledAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
              v-if="bankSlip || (!credit && !debit && !bankSlip)"
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Pendente</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="pending" />
                  <div class="result d-flex align-itens-center border-pending">
                    <h3 class="m-auto font-weight-light">
                      {{ total.pendingQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.pendingAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
              v-if="bankSlip || (!credit && !debit && !bankSlip)"
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Vencido</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="delayed" />
                  <div class="result d-flex align-itens-center border-warning">
                    <h3 class="m-auto font-weight-light">
                      {{ total.delayedQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.delayedAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
              v-if="credit || debit || (!credit && !debit && !bankSlip)"
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Contestado</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="contested" />
                  <div class="result d-flex align-itens-center border-dark">
                    <h3 class="m-auto font-weight-light">
                      {{ total.contestedQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.contestedAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
            <div
              class="
                col-6 col-md-4 col-lg-2 col-xl-2
                d-flex
                justify-content-center
              "
              v-if="credit || debit || (!credit && !debit && !bankSlip)"
            >
              <div class="quantitative text-center">
                <h5 class="mb-3 font-weight-light d-block">Não autorizado</h5>
                <label class="radio-selector mx-auto">
                  <input type="checkbox" v-model="unauthorized" />
                  <div class="result d-flex align-itens-center border-danger">
                    <h3 class="m-auto font-weight-light">
                      {{ total.unauthorizedQuantity }}
                    </h3>
                  </div>
                  <h4 class="mt-3 mb-0 font-weight-light mx-auto">
                    {{
                      total.unauthorizedAmount
                        | currency('R$ ', 2, {
                          decimalSeparator: ',',
                          thousandsSeparator: '.',
                        })
                    }}
                  </h4>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a
        @click.prevent="resetFilters()"
        href="#"
        class="ml-auto mt-1 pull-right mr-4"
      >
        <i class="icon-close"></i> Limpar filtros
      </a>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-w-full col-md-12 col-lg-6 col-xl-4"
          v-for="(payment, index) of this.historyPayment.payments"
          :key="index"
        >
          <div
            class="card table-card border-badge"
            :class="statusColor(payment.status)"
          >
            <div class="card-content">
              <div class="d-flex mb-3">
                <div class="pull-left mr-auto">
                  <span
                    v-if="
                      payment.method === 'debit' || payment.method === 'credit'
                    "
                    class="d-flex payment-method brand-card"
                    :class="payment.cardBrand"
                  >
                    <span class="d-none">{{
                      payment.method === 'debit' ? 'Débito' : 'Crédito'
                    }}</span>
                    <small class="ml-2 align-self-center payment-method-text">{{
                      payment.method === 'debit' ? 'Débito' : 'Crédito'
                    }}</small>
                  </span>
                  <span
                    v-else-if="payment.method === 'debitAndCredit'"
                    class="d-flex payment-method multiple-card"
                  >
                    <span class="d-none">Crédito e Débito</span>
                    <small class="ml-2 align-self-center payment-method-text"
                      >Multiplos</small
                    >
                  </span>
                  <span
                    v-else-if="
                      payment.method === 'bankSlip' &&
                      payment.amount !== payment.installmentValue
                    "
                    class="d-flex payment-method carne"
                    title="Carnê"
                  >
                    <span class="d-none">Parcela de Carnê</span>
                    <small class="ml-2 align-self-center payment-method-text"
                      >Parcela de<br />Carnê</small
                    >
                  </span>
                  <span
                    v-else-if="
                      payment.method === 'bankSlip' &&
                      payment.amount === payment.installmentValue
                    "
                    class="d-flex payment-method boleto"
                    title="Boleto"
                  >
                    <span class="d-none">Boleto</span>
                    <small class="ml-2 align-self-center payment-method-text"
                      >Boleto</small
                    >
                  </span>
                </div>
                <div class="text-right pull-right">
                  <span>
                    <template v-if="payment.method === 'debitAndCredit'">
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-white mb-0 text-sm">•</p>
                    </template>
                    <template
                      v-else-if="
                        payment.method === 'bankSlip' &&
                        payment.installments > 1
                      "
                    >
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.installmentValue
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-muted mb-0 text-sm">
                        Total
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </p>
                    </template>
                    <template
                      v-else-if="
                        payment.method === 'bankSlip' &&
                        payment.installments === 1
                      "
                    >
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-white mb-0 text-sm">•</p>
                    </template>
                    <template
                      v-else-if="
                        payment.method === 'credit' && payment.installments > 1
                      "
                    >
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-muted mb-0 text-sm">
                        {{ payment.installments }} x
                        {{
                          payment.installmentValue
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </p>
                    </template>
                    <template
                      v-else-if="
                        payment.method === 'credit' &&
                        payment.installments === 1
                      "
                    >
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-muted mb-0 text-sm">
                        {{ payment.installments }} x
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </p>
                    </template>
                    <template
                      v-else-if="
                        payment.method === 'bankSlip' &&
                        (payment.installments === null ||
                          payment.installments === 1)
                      "
                    >
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-white mb-0 text-sm">•</p>
                    </template>
                    <template v-else-if="payment.method === 'debit'">
                      <h3 class="font-weight-light mb-0">
                        {{
                          payment.amount
                            | currency('R$ ', 2, {
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </h3>
                      <p class="text-white mb-0 text-sm">•</p>
                    </template>
                  </span>
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="text-left pull-left mr-auto">
                  <h6 class="font-weight-light text-muted mb-0">Cliente:</h6>
                  <p v-if="payment.method !== 'bankSlip'" class="mb-0 text-sm">
                    Nome não informado<br />
                    Documento não informado
                  </p>
                  <span class="text-truncate" v-else>
                    <p class="text-truncate mb-0 text-sm">
                      {{ payment.payerName | abbreviateName(20) }}
                    </p>
                    <p class="mb-0 text-sm">
                      {{
                        payment.payerTaxDocument.length > 14 ? 'CNPJ' : 'CPF'
                      }}: {{ payment.payerTaxDocument }}
                    </p>
                  </span>
                </div>
                <div
                  class="text-right pull-right"
                  v-if="payment.status === 'pending'"
                >
                  <h6
                    class="font-weight-light text-muted mb-0"
                    v-if="payment.nextDueDate !== null"
                  >
                    Próx Vencimento:
                  </h6>
                  <p class="text-sm">
                    {{ payment.nextDueDate | moment('DD/MM/YYYY') }}
                  </p>
                </div>
                <div
                  class="text-right pull-right"
                  v-if="
                    payment.status === 'canceled' ||
                    payment.status === 'autoCanceled'
                  "
                >
                  <h6
                    class="font-weight-light text-muted mb-0"
                    v-if="payment.canceledAt !== null"
                  >
                    Cancelado:
                  </h6>
                  <p class="text-sm">
                    {{ payment.canceledAt | moment('DD/MM/YYYY HH:mm') }}
                  </p>
                </div>
                <div
                  class="text-right pull-right"
                  v-if="payment.status === 'paid'"
                >
                  <h6
                    class="font-weight-light text-muted mb-0"
                    v-if="payment.completedAt !== null"
                  >
                    Pago:
                  </h6>
                  <p class="text-sm">
                    {{ payment.completedAt | moment('DD/MM/YYYY HH:mm') }}
                  </p>
                </div>
                <div
                  class="text-right pull-right"
                  v-if="payment.status === 'delayed'"
                >
                  <h6
                    class="font-weight-light text-muted mb-0"
                    v-if="payment.firstDelayDate !== null"
                  >
                    Vencido:
                  </h6>
                  <p class="text-sm">
                    {{ payment.firstDelayDate | moment('DD/MM/YYYY') }}
                  </p>
                </div>
              </div>
              <div class="d-flex">
                <div class="text-truncate mr-4" v-if="payment.note !== null">
                  <h6 class="font-weight-light text-muted mb-0">
                    Descrição do pagamento:
                  </h6>
                  <p class="text-truncate mb-0 text-sm">{{ payment.note }}</p>
                </div>
                <div class="dropdown pull-right ml-auto">
                  <button
                    type="button"
                    @click="setBankSlip(payment)"
                    class="btn btn-outline-orange btn-pill dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Ações
                    <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        :to="{
                          name: 'payment-details',
                          params: { paymentId: payment.id },
                        }"
                        class="dropdown-item"
                        >Ver detalhes</router-link
                      >
                    </li>
                    <li>
                      <span
                        @click="
                          sendSingleToEmail(
                            payment.payerEmail !== null
                              ? payment.payerEmail
                              : ''
                          )
                        "
                        v-if="
                          payment.method === 'bankSlip' &&
                          payment.status === 'pending'
                        "
                        class="dropdown-item cursor-pointer"
                        >Enviar boleto por e-mail</span
                      >
                    </li>
                    <li v-if="payment.installments > 1">
                      <span
                        @click="
                          sendToEmail(
                            payment.id,
                            payment.payerEmail !== null
                              ? payment.payerEmail
                              : ''
                          )
                        "
                        v-if="
                          payment.method === 'bankSlip' &&
                          payment.status === 'pending'
                        "
                        class="dropdown-item cursor-pointer"
                        >Enviar carnê por e-mail</span
                      >
                    </li>
                    <div
                      class="dropdown-divider"
                      v-if="
                        payment.cancelable &&
                        payment.status !== 'canceled' &&
                        paymentsPermission
                      "
                    ></div>
                    <li
                      v-if="
                        payment.cancelable &&
                        payment.status !== 'canceled' &&
                        paymentsPermission
                      "
                    >
                      <a
                        href="javascript:;"
                        class="dropdown-item"
                        @click.prevent="cancelPayment(payment.id)"
                      >
                        Cancelar
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="historyPayment.count === 0">
        <div class="text-center">
          <h4>Nenhum pagamento encontrado.</h4>
        </div>
      </div>
      <div class="row justify-content-center mx-0">
        <app-paginator
          :count="historyPayment.count"
          :perPage="perPage"
          @exchange="turnPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import swal from 'sweetalert2';
import Alert from '@/models/Alert';
import { mapGetters, mapActions } from 'vuex';
import PaymentsApi from '@/services/PaymentsApi';
import { HistoryPayment, FilterHistoryPayment } from '@/models/HistoryPayment';
import { PermissionList } from '@/models/Permission';
import { mask } from 'vue-the-mask';
import AppPaginator from '@/components/theme/Paginator';
import { cpfCnpj } from '@/validations/cpfCnpj';
import { propName } from '@/validations/propName';
export default {
  name: 'AppPaymentHistory',
  data() {
    return {
      total: {
        pendingQuantity: 0,
        pendingAmount: 0,
        paidQuantity: 0,
        paidAmount: 0,
        contestedQuantity: 0,
        contestedAmount: 0,
        delayedQuantity: 0,
        delayedAmount: 0,
        canceledQuantity: 0,
        canceledAmount: 0,
        registrationErrorQuantity: 0,
        registrationErrorAmount: 0,
        reprovedQuantity: 0,
        reprovedAmount: 0,
      },
      customRange: false,
      permissionList: new PermissionList(),
      historyPayment: new HistoryPayment(),
      filters: new FilterHistoryPayment(),
      perPage: 12,
      count: 0,
      timeout: 500,
      showInputName: false,
      showInputCPF: false,
      bankSlip: false,
      credit: false,
      debit: false,
      paid: false,
      delayed: false,
      unauthorized: false,
      pending: false,
      canceled: false,
      contested: false,
      last7Days: false,
      last30Days: false,
      startDate: '',
      endDate: '',
      cardBrand: '',
      searchType: 'payerTaxDocument',
      payerTaxDocument: '',
      payerNameIndex: 0,
      payerName: '',
      bankslipIdIndex: 0,
      bankslipId: '',
      bankslip: null,
      nsuIndex: 0,
      nsu: '',
      cardFinal: '',
      noteIndex: 0,
      note: '',
      customer: {
        email: '',
      },
    };
  },
  async mounted() {
    this.$bus.$emit('spinner-run');
    this.initFilters();
    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    await this.getHistoryPayment();
    this.total = this.historyPayment.total;
    this.$bus.$emit('spinner-stop');
    this.$bus.$emit('refresh-warnings');
  },
  methods: {
    ...mapActions({
      keepShowInputName: 'paymentFilters/keepShowInputName',
      keepShowInputCPF: 'paymentFilters/keepShowInputCPF',
      keepBankSlip: 'paymentFilters/keepBankSlip',
      keepCredit: 'paymentFilters/keepCredit',
      keepDebit: 'paymentFilters/keepDebit',
      keepPaid: 'paymentFilters/keepPaid',
      keepDelayed: 'paymentFilters/keepDelayed',
      keepUnauthorized: 'paymentFilters/keepUnauthorized',
      keepPending: 'paymentFilters/keepPending',
      keepCanceled: 'paymentFilters/keepCanceled',
      keepContested: 'paymentFilters/keepContested',
      keepLast7Days: 'paymentFilters/keepLast7Days',
      keepLast30Days: 'paymentFilters/keepLast30Days',
      keepStartDate: 'paymentFilters/keepStartDate',
      keepEndDate: 'paymentFilters/keepEndDate',
      keepCardBrand: 'paymentFilters/keepCardBrand',
      keepSearchType: 'paymentFilters/keepSearchType',
      keepPayerTaxDocument: 'paymentFilters/keepPayerTaxDocument',
      keepPayerNameIndex: 'paymentFilters/keepPayerNameIndex',
      keepPayerName: 'paymentFilters/keepPayerName',
      keepBankslipIdIndex: 'paymentFilters/keepBankslipIdIndex',
      keepBankslipId: 'paymentFilters/keepBankslipId',
      keepNsuIndex: 'paymentFilters/keepNsuIndex',
      keepNsu: 'paymentFilters/keepNsu',
      keepCardFinal: 'paymentFilters/keepCardFinal',
      keepNoteIndex: 'paymentFilters/keepNoteIndex',
      keepNote: 'paymentFilters/keepNote',
    }),
    checkCustomRange() {
      this.customRange = !this.customRange;
      if (this.customRange) {
        this.addDatePicker();
      }
    },
    async getHistoryPayment() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentsApi();
      var result = await api.getHistoryPayment(this.filters);
      this.historyPayment.payments = result.payments.slice(0, this.perPage);
      this.historyPayment.count = result.count;
      this.historyPayment.total = result.total;
      this.$bus.$emit('spinner-stop');
    },
    async turnPage(numberPage) {
      const fim = numberPage * this.perPage;
      const inicio = fim - this.perPage;
      this.filters.offset.index = inicio;
      await this.getHistoryPayment();
    },
    getStatusPayment(type) {
      return this.historyPayment.getStatus(type);
    },
    statusColor(type) {
      return {
        'border-left-success': type === 'paid',
        'border-left-delayed': type === 'delayed',
        'border-left-danger': type === 'reproved' || type === 'unauthorized',
        'border-left-pending': type === 'pending',
        'border-left-canceled': type === 'canceled' || type === 'autoCanceled',
        'border-left-contested': type === 'contested',
      };
    },
    showSearchName() {
      this.showInputName = true;
      this.showInputCPF = false;
      this.filters.payerTaxDocument = '';
    },
    showSearchTaxDocument() {
      this.showInputCPF = true;
      this.showInputName = false;
      this.filters.payerName = '';
    },
    clearFilters() {
      this.filters = new FilterHistoryPayment();
      this.customRange = false;
      this.showInputName = false;
      this.showInputCPF = false;
    },
    payerNameTimeoutCalc() {
      var _this = this;
      var payerNameIndexMoment = ++this.payerNameIndex;
      setTimeout(function () {
        if (_this.payerNameIndex === payerNameIndexMoment) {
          _this.filters.payerName = _this.payerName;
          _this.getHistoryPayment();
        } else if (_this.payerNameIndex > 0 && _this.payerName === '') {
          _this.filters.payerName = '';
          _this.payerNameIndex = 0;
          _this.getHistoryPayment();
        }
      }, this.timeout);
    },
    nsuTimeoutCalc() {
      var _this = this;
      var nsuIndexMoment = ++this.nsuIndex;
      setTimeout(function () {
        if (_this.nsuIndex === nsuIndexMoment) {
          _this.filters.nsu = _this.nsu;
          _this.getHistoryPayment();
        } else if (_this.nsuIndex > 0 && _this.nsu === '') {
          _this.filters.nsu = '';
          _this.nsuIndex = 0;
          _this.getHistoryPayment();
        }
      }, this.timeout);
    },
    bankslipIdTimeoutCalc() {
      var _this = this;
      var bankslipIdIndexMoment = ++this.bankslipIdIndex;
      setTimeout(function () {
        if (_this.bankslipIdIndex === bankslipIdIndexMoment) {
          _this.filters.bankslipId = _this.bankslipId;
          _this.getHistoryPayment();
        } else if (_this.bankslipIdIndex > 0 && _this.bankslipId === '') {
          _this.filters.bankslipId = '';
          _this.bankslipIdIndex = 0;
          _this.getHistoryPayment();
        }
      }, this.timeout);
    },
    async cancelPayment(id) {
      this.$alert.question(
        'Cancelamento de pagamento',
        'Deseja realmente cancelar todo o pagamento?'
      );
      this.$alert.onConfirm = async () => {
        const api = new PaymentsApi();
        this.$bus.$emit('spinner-run');
        await api.cancelPayment(id);
        await this.getHistoryPayment();
        this.$bus.$emit('spinner-stop');
      };
    },
    noteTimeoutCalc() {
      var _this = this;
      var noteIndexMoment = ++this.noteIndex;
      setTimeout(function () {
        if (_this.noteIndex === noteIndexMoment) {
          _this.filters.note = _this.note;
          _this.getHistoryPayment();
        } else if (_this.noteIndex > 0 && _this.note === '') {
          _this.filters.note = '';
          _this.noteIndex = 0;
          _this.getHistoryPayment();
        }
      }, this.timeout);
    },
    getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    initFilters() {
      this.showInputName = this.showInputNameStore;
      this.showInputCPF = this.showInputCPFStore;
      this.bankSlip = this.bankSlipStore;
      this.credit = this.creditStore;
      this.debit = this.debitStore;
      this.paid = this.paidStore;
      this.delayed = this.delayedStore;
      this.unauthorized = this.unauthorizedStore;
      this.pending = this.pendingStore;
      this.canceled = this.canceledStore;
      this.contested = this.contestedStore;
      this.last7Days = this.last7DaysStore;
      this.last30Days = this.last30DaysStore;
      this.startDate = this.startDateStore;
      this.endDate = this.endDateStore;
      this.cardBrand = this.cardBrandStore;
      this.searchType = this.searchTypeStore;
      this.payerTaxDocument = this.payerTaxDocumentStore;
      this.payerNameIndex = this.payerNameIndexStore;
      this.payerName = this.payerNameStore;
      this.bankslipIdIndex = this.bankslipIdIndexStore;
      this.bankslipId = this.bankslipIdStore;
      this.nsuIndex = this.nsuIndexStore;
      this.nsu = this.nsuStore;
      this.cardFinal = this.cardFinalStore;
      this.noteIndex = this.noteIndexStore;
      this.note = this.noteStore;
      this.total = this.historyPayment.total;

      this.displayFiltersToFilters();
    },
    resetFilters() {
      this.showInputName = false;
      this.showInputCPF = false;
      this.bankSlip = false;
      this.credit = false;
      this.debit = false;
      this.paid = false;
      this.delayed = false;
      this.unauthorized = false;
      this.pending = false;
      this.canceled = false;
      this.contested = false;
      this.last7Days = false;
      this.last30Days = false;
      this.startDate = '';
      this.endDate = '';
      this.cardBrand = '';
      this.searchType = 'payerTaxDocument';
      this.payerTaxDocument = '';
      this.payerNameIndex = 0;
      this.payerName = '';
      this.bankslipIdIndex = 0;
      this.bankslipId = '';
      this.nsuIndex = 0;
      this.nsu = '';
      this.cardFinal = '';
      this.noteIndex = 0;
      this.note = '';

      this.displayFiltersToFilters();
    },
    displayFiltersToFilters() {
      this.filters.startDate = this.startDate;
      this.filters.endDate = this.endDate;
      this.filters.paymentMethod.bankSlip = this.bankSlip ? 'bankSlip' : '';
      this.filters.paymentMethod.credit = this.credit ? 'credit' : '';
      this.filters.paymentMethod.debit = this.debit ? 'debit' : '';
      this.filters.status.paid = this.paid ? 'paid' : '';
      this.filters.status.delayed = this.delayed ? 'delayed' : '';
      this.filters.status.unauthorized = this.unauthorized
        ? 'unauthorized'
        : '';
      this.filters.status.pending = this.pending ? 'pending' : '';
      this.filters.status.canceled = this.canceled ? 'canceled' : '';
      this.filters.status.contested = this.contested ? 'contested' : '';
      this.filters.payerName = this.payerName;
      this.filters.payerTaxDocument = this.payerTaxDocument;
      this.filters.nsu = this.nsu;
      this.filters.cardBrand = this.cardBrand;
      this.filters.cardFinal = this.cardFinal;
      this.filters.bankslipId = this.bankslipId;
      this.filters.note = this.note;
    },
    async setBankSlip(payment) {
      const api = new PaymentsApi();
      const paymentResult = await api.findPayment(payment.id);

      paymentResult.bankSlips.forEach((bankslip) => {
        if (bankslip.dueDate === payment.nextDueDate) {
          this.bankslip = bankslip;
        }
      });
    },
    async sendSingleToEmail(payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();
          await api
            .singleBankSlipSendToEmail(this.bankslip.id, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Boleto enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },
    async sendToEmail(paymentId, payerEmail) {
      swal({
        title: 'Digite o e-mail',
        input: 'text',
        inputValue: payerEmail,
        inputPlaceholder: '',
        inputAttributes: {
          minlength: 8,
          autocapitalize: 'off',
        },
        confirmButtonText: 'Enviar',
        showLoaderOnConfirm: true,
        showCloseButton: true,
        preConfirm: (email) => {
          var regex = new RegExp(
            '^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
          );
          if (!email || email.length === 0) {
            swal.showValidationError('E-mail obrigatório!');
          } else if (!regex.test(email)) {
            swal.showValidationError('E-mail inválido!');
          } else {
            this.customer.email = email;
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then(async (res) => {
        if (
          res.dismiss &&
          (res.dismiss === 'close' ||
            res.dismiss === 'cancel' ||
            res.dismiss === 'overlay')
        ) {
          !swal.isLoading();
        } else {
          this.$bus.$emit('spinner-run');
          const api = new PaymentsApi();
          await api
            .bankSlipSendToEmail(paymentId, this.customer)
            .then((result) => {
              if (result.error) {
                this.$bus.$emit('spinner-stop');
                var alert = new Alert();
                alert.title = 'Ops';
                alert.error = 'Você digitou um e-mail inválido.';
                alert.confirmButtonText = 'Sair';
                this.$bus.$emit('alert-error', alert);
              } else {
                this.$bus.$emit('spinner-stop');
                this.isDisabled = true;
                this.$alert.info('Carnê enviado', '');
              }
            })
            .catch((data) => {
              this.$bus.$emit('spinner-stop');
              var alert = new Alert();
              alert.title = 'Ops';
              alert.error =
                'Servidores com preguiça, tente novamente mais tarde.';
              alert.confirmButtonText = 'Ok';
              this.$bus.$emit('alert-error', alert);
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
      showInputNameStore: 'paymentFilters/showInputName',
      showInputCPFStore: 'paymentFilters/showInputCPF',
      bankSlipStore: 'paymentFilters/bankSlip',
      creditStore: 'paymentFilters/credit',
      debitStore: 'paymentFilters/debit',
      paidStore: 'paymentFilters/paid',
      delayedStore: 'paymentFilters/delayed',
      unauthorizedStore: 'paymentFilters/unauthorized',
      pendingStore: 'paymentFilters/pending',
      canceledStore: 'paymentFilters/canceled',
      contestedStore: 'paymentFilters/contested',
      last7DaysStore: 'paymentFilters/last7Days',
      last30DaysStore: 'paymentFilters/last30Days',
      startDateStore: 'paymentFilters/startDate',
      endDateStore: 'paymentFilters/endDate',
      cardBrandStore: 'paymentFilters/cardBrand',
      searchTypeStore: 'paymentFilters/searchType',
      payerTaxDocumentStore: 'paymentFilters/payerTaxDocument',
      payerNameIndexStore: 'paymentFilters/payerNameIndex',
      payerNameStore: 'paymentFilters/payerName',
      bankslipIdIndexStore: 'paymentFilters/bankslipIdIndex',
      bankslipIdStore: 'paymentFilters/bankslipId',
      nsuIndexStore: 'paymentFilters/nsuIndex',
      nsuStore: 'paymentFilters/nsu',
      cardFinalStore: 'paymentFilters/cardFinal',
      noteIndexStore: 'paymentFilters/noteIndex',
      noteStore: 'paymentFilters/note',
    }),
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments'
      );
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    paymentsPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    showCustomRange() {
      return this.customRange;
    },
    invalidSearch() {
      return this.$v.$invalid;
    },
  },
  watch: {
    bankSlip: async function () {
      if (this.bankSlip) {
        this.filters.paymentMethod.bankSlip = 'bankSlip';
        this.searchType = 'bankslipId';
      } else {
        this.filters.paymentMethod.bankSlip = '';
        if (this.credit || this.debit) {
          this.searchType = 'nsu';
        }
      }
      this.keepBankSlip(this.bankSlip);
      await this.getHistoryPayment();
    },
    credit: async function () {
      if (this.credit) {
        this.filters.paymentMethod.credit = 'credit';
        this.searchType = 'nsu';
      } else {
        this.filters.paymentMethod.credit = '';
        if (!this.debit && this.bankSlip) {
          this.searchType = 'bankslipId';
        }
      }
      this.keepCredit(this.credit);
      await this.getHistoryPayment();
    },
    debit: async function () {
      if (this.debit) {
        this.filters.paymentMethod.debit = 'debit';
        this.searchType = 'nsu';
      } else {
        this.filters.paymentMethod.debit = '';
        if (!this.credit && this.bankSlip) {
          this.searchType = 'bankslipId';
        }
      }
      this.keepDebit(this.debit);
      await this.getHistoryPayment();
    },
    paid: async function () {
      if (this.paid) {
        this.filters.status.paid = 'paid';
      } else {
        this.filters.status.paid = '';
      }
      this.keepPaid(this.paid);
      await this.getHistoryPayment();
    },
    contested: async function () {
      if (this.contested) {
        this.filters.status.contested = 'contested';
      } else {
        this.filters.status.contested = '';
      }
      this.keepContested(this.contested);
      await this.getHistoryPayment();
    },
    canceled: async function () {
      if (this.canceled) {
        this.filters.status.canceled = 'canceled';
      } else {
        this.filters.status.canceled = '';
      }
      this.keepCanceled(this.canceled);
      await this.getHistoryPayment();
    },
    delayed: async function () {
      if (this.delayed) {
        this.filters.status.delayed = 'delayed';
      } else {
        this.filters.status.delayed = '';
      }
      this.keepDelayed(this.delayed);
      await this.getHistoryPayment();
    },
    unauthorized: async function () {
      if (this.unauthorized) {
        this.filters.status.unauthorized = 'unauthorized';
      } else {
        this.filters.status.unauthorized = '';
      }
      this.keepUnauthorized(this.unauthorized);
      await this.getHistoryPayment();
    },
    pending: async function () {
      if (this.pending) {
        this.filters.status.pending = 'pending';
      } else {
        this.filters.status.pending = '';
      }
      this.keepPending(this.pending);
      await this.getHistoryPayment();
    },
    last7Days: async function () {
      if (this.last7Days) {
        this.last30Days = false;
        this.startDate = '';
        this.endDate = '';
        this.filters.last7Days();
      } else if (!this.last7Days && !this.last30Days) {
        this.filters.clearDates();
      }
      this.keepLast7Days(this.last7Days);
      await this.getHistoryPayment();
    },
    last30Days: async function () {
      if (this.last30Days) {
        this.last7Days = false;
        this.startDate = '';
        this.endDate = '';
        this.filters.last30Days();
      } else if (!this.last7Days && !this.last30Days) {
        this.filters.clearDates();
      }
      this.keepLast30Days(this.last30Days);
      await this.getHistoryPayment();
    },
    startDate: async function () {
      if (this.startDate !== '') {
        this.last7Days = false;
        this.last30Days = false;
      }
      if (this.startDate < this.endDate) {
        this.filters.startDate = this.startDate;
        this.filters.endDate = this.endDate;
        this.keepStartDate(this.startDate);
        await this.getHistoryPayment();
      }
    },
    endDate: async function () {
      if (this.endDate !== '') {
        this.last7Days = false;
        this.last30Days = false;
      }
      if (this.startDate !== '' && this.startDate < this.endDate) {
        this.filters.startDate = this.startDate;
        this.filters.endDate = this.endDate;
        this.keepEndDate(this.endDate);
        await this.getHistoryPayment();
      }
    },
    payerTaxDocument: async function () {
      if (!this.$v.payerTaxDocument.$invalid) {
        this.filters.payerTaxDocument = this.payerTaxDocument;
      } else {
        if (this.filters.payerTaxDocument !== '') {
          this.filters.payerTaxDocument = '';
        }
      }
      this.keepPayerTaxDocument(this.payerTaxDocument);
      await this.getHistoryPayment();
    },
    payerName: async function () {
      this.payerNameTimeoutCalc();
      this.keepPayerName(this.payerName);
      this.keepPayerNameIndex(this.payerNameIndex);
    },
    cardBrand: async function () {
      if (this.cardBrand !== '') {
        this.filters.cardBrand = this.cardBrand;
        this.bankSlip = false;
      } else {
        this.filters.cardBrand = '';
      }
      this.keepCardBrand(this.cardBrand);
      await this.getHistoryPayment();
    },
    searchType: function () {
      if (this.searchType !== 'payerTaxDocument') {
        this.payerTaxDocument = '';
      } else if (this.searchType !== 'payerName') {
        this.payerNameIndex = 0;
        this.payerName = '';
      } else if (this.searchType !== 'cardFinal') {
        this.cardFinal = '';
      } else if (this.searchType !== 'bankslipIdIndex') {
        this.bankslipId = '';
        this.bankslipIdIndex = 0;
      } else if (this.searchType !== 'nsu') {
        this.nsuIndex = 0;
        this.nsu = '';
      }
      this.keepSearchType(this.searchType);
    },
    cardFinal: async function () {
      this.cardFinal = this.cardFinal.replace(/[^0-9]/g, '');
      if (this.cardFinal.length === 4) {
        this.filters.cardFinal = this.cardFinal;
      } else if (
        (this.cardFinal.length === 3 || this.cardFinal.length === 0) &&
        this.filters.cardFinal !== ''
      ) {
        this.filters.cardFinal = '';
      }
      this.keepCardFinal(this.cardFinal);
      await this.getHistoryPayment();
    },
    nsu: async function () {
      this.nsu = this.nsu.replace(/[^0-9]/g, '');
      this.nsuTimeoutCalc();
      this.keepNsu(this.nsu);
      this.keepNsuIndex(this.nsuIndex);
    },
    bankslipId: async function () {
      this.bankslipId = this.bankslipId.replace(/[^0-9]/g, '');
      this.bankslipIdTimeoutCalc();
      this.keepBankslipId(this.bankslipId);
      this.keepBankslipIdIndex(this.bankslipIdIndex);
    },
    note: async function () {
      this.noteTimeoutCalc();
      this.keepNote(this.note);
      this.keepNoteIndex(this.noteIndex);
    },
  },
  directives: {
    mask,
  },
  validations: {
    filters: {
      payerName: {
        propName,
      },
      payerTaxDocument: {
        cpfCnpj,
      },
    },
    payerTaxDocument: {
      cpfCnpj,
    },
  },
  components: {
    AppPaginator,
  },
};
</script>
<style lang="scss">
#action {
  cursor: pointer;
}
.swal2-input {
  width: 50% !important;
}
</style>
