<template>
  <div>
    <form @submit.prevent="changeBankslip">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-w-full col-lg-6">
            <div class="card">
              <div class="d-flex">
                <h3 class="font-weight-light ml-3 mr-auto pull-left">
                  Substituir boleto
                </h3>
                <a
                  href="#"
                  @click="$router.go(-1)"
                  class="pull-right mt-2 mr-4"
                >
                  <i class="icon-arrow-left-circle"></i> Voltar
                </a>
              </div>
              <hr />
              <div class="card-content">
                <div class="row justify-content-center">
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Vencimento</label>
                    <input
                      class="form-control"
                      type="date"
                      v-model="bankSlip.dueDate"
                    />
                    <small class="text-danger mb-0" v-if="isBefore">
                      O vencimento do boleto não pode ser anterior ao dia em que
                      ele é gerado</small
                    >
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Aceitar após vencimento?</label>
                    <select
                      class="form-control"
                      name="acceptedUntil"
                      v-model="bankSlip.acceptedUntil"
                    >
                      <option value="0" selected="selected">Não aceitar</option>
                      <option value="1">Até 1 dia</option>
                      <option value="2">Até 2 dias</option>
                      <option value="3">Até 3 dias</option>
                      <option value="4">Até 4 dias</option>
                      <option value="5">Até 5 dias</option>
                      <option value="6">Até 6 dias</option>
                      <option value="7">Até 7 dias</option>
                      <option value="8">Até 8 dias</option>
                      <option value="9">Até 9 dias</option>
                      <option value="10">Até 10 dias</option>
                      <option value="11">Até 11 dias</option>
                      <option value="12">Até 12 dias</option>
                      <option value="13">Até 13 dias</option>
                      <option value="14">Até 14 dias</option>
                      <option value="15">Até 15 dias</option>
                      <option value="16">Até 16 dias</option>
                      <option value="17">Até 17 dias</option>
                      <option value="18">Até 18 dias</option>
                      <option value="19">Até 19 dias</option>
                      <option value="20">Até 20 dias</option>
                      <option value="21">Até 21 dias</option>
                      <option value="22">Até 22 dias</option>
                      <option value="23">Até 23 dias</option>
                      <option value="24">Até 24 dias</option>
                      <option value="25">Até 25 dias</option>
                    </select>
                    <small> Mínimo 1 dia </small>
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Multa (%)</label>
                    <select
                      class="form-control"
                      name="fines"
                      v-model="bankSlip.fines"
                    >
                      <option value="0">Não haverá multa</option>
                      <option value="0.25">0,25%</option>
                      <option value="0.5">0,5%</option>
                      <option value="0.75">0,75%</option>
                      <option value="1">1%</option>
                      <option value="2">2% (máx por lei)</option>
                      <option value="3">3%</option>
                      <option value="4">4%</option>
                      <option value="5">5%</option>
                      <option value="6">6%</option>
                      <option value="7">7%</option>
                      <option value="8">8%</option>
                      <option value="9">9%</option>
                      <option value="10">10%</option>
                      <option value="11">11%</option>
                      <option value="12">12%</option>
                      <option value="13">13%</option>
                      <option value="14">14%</option>
                      <option value="15">15%</option>
                      <option value="16">16%</option>
                      <option value="17">17%</option>
                      <option value="18">18%</option>
                      <option value="19">19%</option>
                      <option value="20">20%</option>
                    </select>
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Juros após vencimento (%)</label>
                    <select
                      class="form-control"
                      name="interest"
                      v-model="bankSlip.interest"
                    >
                      <option value="0">Não haverão juros</option>
                      <option value="0.25">0,25%</option>
                      <option value="0.5">0,5%</option>
                      <option value="0.75">0,75%</option>
                      <option value="1">1% (máx por lei)</option>
                      <option value="2">2%</option>
                      <option value="3">3%</option>
                      <option value="4">4%</option>
                      <option value="5">5%</option>
                      <option value="6">6%</option>
                      <option value="7">7%</option>
                      <option value="8">8%</option>
                      <option value="9">9%</option>
                      <option value="10">10%</option>
                      <option value="11">11%</option>
                      <option value="12">12%</option>
                      <option value="13">13%</option>
                      <option value="14">14%</option>
                      <option value="15">15%</option>
                      <option value="16">16%</option>
                      <option value="17">17%</option>
                      <option value="18">18%</option>
                      <option value="19">19%</option>
                      <option value="20">20%</option>
                    </select>
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Oferecer desconto?</label>
                    <select
                      class="form-control"
                      name="discountDays"
                      v-model="bankSlip.discountDays"
                    >
                      <option value="">Sem desconto</option>
                      <option value="0">Até o vencimento</option>
                      <option value="1">Até 1 dia antes</option>
                      <option value="2">Até 2 dias antes</option>
                      <option value="3">Até 3 dias antes</option>
                      <option value="4">Até 4 dias antes</option>
                      <option value="5">Até 5 dias antes</option>
                      <option value="6">Até 6 dias antes</option>
                      <option value="7">Até 7 dias antes</option>
                      <option value="8">Até 8 dias antes</option>
                      <option value="9">Até 9 dias antes</option>
                      <option value="10">Até 10 dias antes</option>
                      <option value="11">Até 11 dias antes</option>
                      <option value="12">Até 12 dias antes</option>
                      <option value="13">Até 13 dias antes</option>
                      <option value="14">Até 14 dias antes</option>
                      <option value="15">Até 15 dias antes</option>
                      <option value="16">Até 16 dias antes</option>
                      <option value="17">Até 17 dias antes</option>
                      <option value="18">Até 18 dias antes</option>
                      <option value="19">Até 19 dias antes</option>
                      <option value="20">Até 20 dias antes</option>
                      <option value="21">Até 21 dias antes</option>
                      <option value="22">Até 22 dias antes</option>
                      <option value="23">Até 23 dias antes</option>
                      <option value="24">Até 24 dias antes</option>
                      <option value="25">Até 25 dias antes</option>
                      <option value="26">Até 26 dias antes</option>
                      <option value="27">Até 27 dias antes</option>
                      <option value="28">Até 28 dias antes</option>
                      <option value="29">Até 29 dias antes</option>
                      <option value="30">Até 30 dias antes</option>
                    </select>
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Desconto (%)</label>
                    <input
                      class="form-control"
                      type="number"
                      step="0.01"
                      placeholder="Digite aqui o desconto"
                      :max="maxDiscount"
                      v-model="bankSlip.discount"
                      :disabled="
                        bankSlip.discountDays === 0 ||
                        bankSlip.discountDays === ''
                      "
                    />
                    <small> Máximo {{ maxDiscount }}% </small>
                  </div>
                  <div
                    class="
                      form-group
                      col-12 col-w-full col-md-10 col-lg-12 col-xl-10
                      mb-4
                    "
                  >
                    <label>Instruções</label>
                    <textarea
                      class="form-control"
                      type="text"
                      placeholder="Digite as instruções"
                      v-model="bankSlip.instructions"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-5 col-lg-4">
            <button
              class="btn btn-outline-orange p-3 h4 w-100"
              @click="$router.go(-1)"
            >
              Cancelar
            </button>
          </div>
          <div class="col-5 col-lg-4">
            <button class="btn btn-orange p-3 h4 w-100" :disabled="isBefore">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PaymentsApi from '@/services/PaymentsApi';
import moment from 'moment';

export default {
  name: 'AppChangeBankslip',
  mounted() {
    this.maxDiscount = this.$route.params.maxDiscount;
  },
  data() {
    return {
      bankSlip: {
        discount: '',
        fines: '',
        interest: '',
        discountDays: 0,
        acceptedUntil: 0,
        dueDate: '',
        instructions: '',
      },
      maxDiscount: 0,
    };
  },
  methods: {
    async changeBankslip() {
      const api = new PaymentsApi();
      this.$bus.$emit('spinner-run');
      if (
        this.bankSlip.discountDays === '' ||
        this.bankSlip.discountDays === 0
      ) {
        this.bankSlip.discountDays = null;
      }
      const result = await api.changeBankslip(
        this.$route.params.bankSlipNumber,
        this.bankSlip
      );
      if (result.error) {
        const title = 'Ops';
        this.$alert.error(title, 'O boleto não pôde ser substituido');
        this.$bus.$emit('spinner-stop');
      } else {
        const title = 'Boleto substituido';
        this.$alert.onClose = async() => this.$router.go(-1);
        this.$alert.info(title, 'O boleto foi substituido com sucesso.');
        this.$bus.$emit('spinner-stop');
      }
    },
  },
  computed: {
    isBefore() {
      const dueDate = moment(this.bankSlip.dueDate, 'YYYY-MM-DD').toDate();
      const today = moment(new Date()).format('YYYY-MM-DD');
      return moment(dueDate).isBefore(today);
    },
  },
};
</script>
