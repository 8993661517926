import { withParams } from 'vuelidate/lib';
import { noAccents } from '@/functions/noAccents';

const propName = withParams({ type: 'propName' }, value => {
  var str = noAccents(value);
  var regex = new RegExp("^[a-zA-Z]+[a-zA-Z'\\s]*$");
  var result = regex.exec(str);
  if (!result) return false;
  return true;
});

export { propName };
