import { Offset } from '@/models/Offset';
const moment = require('moment');
require('moment/locale/pt-br');

const paid = 'PAGO';
const pending = 'A VENCER';
const delayed = 'VENCIDO';
const canceled = 'CANCELADO';
const contested = 'CONSTESTADO';
const registrationError = 'ERRO NO REGISTRO';
const unauthorized = 'NÃO AUTORIZADO';

export class HistoryPayment {
  payments = [];
  count = 0;
  offset = new Offset()
  total = {
    pendingQuantity: 0,
    pendingAmount: 0,
    paidQuantity: 0,
    paidAmount: 0,
    contestedQuantity: 0,
    contestedAmount: 0,
    delayedQuantity: 0,
    delayedAmount: 0,
    canceledQuantity: 0,
    canceledAmount: 0,
    registrationErrorQuantity: 0,
    registrationErrorAmount: 0,
    unauthorizedAmount: 0,
  };
  getStatus(status) {
    switch (status) {
    case Object.keys({ paid })[0]:
      return paid;
    case Object.keys({ pending })[0]:
      return pending;
    case Object.keys({ delayed })[0]:
      return delayed;
    case Object.keys({ canceled })[0]:
      return canceled;
    case Object.keys({ contested })[0]:
      return contested;
    case Object.keys({ registrationError })[0]:
      return registrationError;
    case Object.keys({ unauthorized })[0]:
      return unauthorized;
    default:
      break;
    }
  }
}

export class FilterHistoryPayment {
  startDate = ''
  endDate = ''
  paymentMethod = { bankSlip: '', credit: '', debit: '' }
  status = { paid: '', delayed: '', unauthorized: '', pending: '', canceled: '', contested: '' }
  payerName = ''
  payerTaxDocument = ''
  nsu = ''
  cardBrand = ''
  cardFinal = ''
  bankslipId = ''
  note = ''
  offset = new Offset()

  last7Days() {
    var startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    var endDate = moment().format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
  }

  last30Days() {
    var startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    var endDate = moment().format('YYYY-MM-DD');
    this.startDate = startDate;
    this.endDate = endDate;
  }

  addMethodPayment(method) {
    var contains = this.paymentMethod.includes(method);

    if (!contains) {
      this.paymentMethod.push(method);
    } else {
      var location = this.paymentMethod.indexOf(method);
      if (location > -1) {
        this.paymentMethod.splice(location, 1);
      }
    }
  }

  addPaymentStatus(state) {
    var isAlert = state === 'alert';
    var contains;

    if (isAlert) {
      contains = this.status.includes('contested');
    } else {
      contains = this.status.includes(state);
    }

    if (!contains) {
      if (isAlert) {
        this.status.push('contested');
        this.status.push('unauthorized');
      } else {
        this.status.push(state);
      }
    } else {
      if (isAlert) {
        var list = ['contested', 'unauthorized'];
        list.forEach(element => {
          var location = this.status.indexOf(element);
          this.status.splice(location, 1);
        });
      } else {
        var location = this.status.indexOf(state);
        if (location > -1) {
          this.status.splice(location, 1);
        }
      }
    }
  }

  clearDates() {
    this.startDate = '';
    this.endDate = '';
  }

  addTypeDate(type) {
    this.typeDate = type;
    if (this.typeDate === 'pay') {
      this.clearDateDues();
    } else {
      this.clearDatePays();
    }
  }
}
