<template>
  <WhiteLabel>
    <pagination
      v-if="countItems >= perPage"
      count-text="Mostrando {from} a {to} de {count} registros"
      :records="countItems"
      :per-page="perPage"
      @paginate="emitEvent"
      :page="page"
      :theme="theme"
    />
  </WhiteLabel>
</template>

<script>
import WhiteLabel from '@/components/shared/WhiteLabel';

export default {
  name: 'AppPagination',
  components: {
    WhiteLabel,
  },

  props: {
    count: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      theme: 'bootstrap4',
      page: 1,
    };
  },
  methods: {
    emitEvent(page) {
      this.page = page;
      this.$emit('exchange', page);
    },
  },
  computed: {
    countItems() {
      return this.count;
    },
  },
};
</script>

<style>
.VuePagination__pagination-item-prev-chunk {
  display: none;
}
.VuePagination__pagination-item-next-chunk {
  display: none;
}
.VuePagination__count {
  display: none;
}
</style>
