import CustomApiError from './CustomError';

export default class BankSlipPdfError extends CustomApiError {
  toDictionary() {
    return {
      PAYMENT_NOT_FOUND: 'Pagamento não encontrado.',
      PAYMENT_WITHOUT_BANK_SLIP: 'Pagamento não possui boleto bancário.',
      PDF_CREATION_FAILED: 'Falha na criação do PDF.',
    };
  }
}
